<template>
  <div v-loading="contentLoading" class="detail">
    <h3 class="noticeTitle">{{publicNotice.noticeTitle}}</h3>
    <div class="noticeInfo">{{publicNotice.publishDate}}</div>
    <div class="noticeContent" v-html="publicNotice.noticeDesc"></div>
  </div>
</template>

<script>
  const moduleName = "publicNotices";

  export default {
    data() {
      return {
        contentLoading: false,
        publicNotice: {},
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.contentLoading = true;
        this.$api.getById(moduleName, this.$route.params.id).then(res => {
          this.publicNotice = res.data;
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";

.detail {
  text-align: center;

  .noticeTitle {
    color: #555;
  }

  .noticeInfo {
    padding-bottom: 10px;
    font-size: 10px;
    color: #6f7180;
    border-bottom: 1px solid #D8D8D8;
  }

  .noticeContent {
    text-align: left;
    padding: 0 20px;
  }
}
</style>
